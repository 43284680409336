import React from 'react';
import { Table } from 'antd';

const UnderPerformersTable = ({ data }) => {
  const dataSource = data.map((stu, index) => (
    {
      key: stu.name,
      name: stu.name,
      index: data.length - index
    }
  ));

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    }
  ];

  return (
    <div>
      <p>{data.length} Under Performers</p>
      <Table columns={columns} dataSource={dataSource} />
    </div>
  );
}

export default UnderPerformersTable;