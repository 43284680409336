import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web"; // eslint-disable-line
import Welcome from "./components/Welcome/Welcome";
import { Content, Header } from 'antd/es/layout/layout';
import { ConfigProvider, Layout } from 'antd';
import RolesRoute from "./components/RouteHelpers/RolesRoute";
import AdminOnlyRoute from "./components/AdminComponents/AdminOnlyRoute";
import './App.css'
import LandingPage from "./components/LandingPage/LandingPage";
import ExcalidrawComponent from "./components/Excalidraw/ExcalidrawComponent";
import OverView from "./components/Overview";
import StrengthAnalysis from "./components/StrengthAnalysis";
import Feedback from "./components/Feedback";
// import SideNav from "./components/Nav/SideNav";
// import CustomFooter from "./components/Footer/Footer";
import ClassDashboardView from './components/TutorComponents/Classroom';
import userService from './Services/UserService';
import NewAppHeader from './components/Header/NewAppHeader';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import NavBar from './components/Nav/NavBar';

const App = () => {
  const { keycloak } = useKeycloak();

  const classViewEnabled = userService.hasRole(['admin','school']);
  const screens = useBreakpoint();

  // Determine if the device is mobile based on screen width
  const isMobile = !screens.md;

  return (<ConfigProvider
    theme={{
      token: {
        colorPrimary: '#4d0073',
        colorWarning: '#fa4d14',
        wireframe: true,
        fontFamily: 'Poppins'

      }
    }}
  >
    <Layout>
      <BrowserRouter>
        {/* {!!keycloak.authenticated && !classViewEnabled && <SideNav />} */}
        <Layout>
          <Header style={{ width: '100%' }}>
            <NewAppHeader />
          </Header>
          {!!keycloak.authenticated && !classViewEnabled && !isMobile && <NavBar />}
          <Content style={{
            ...(keycloak.authenticated && !classViewEnabled ? { // eslint-disable-line
              marginTop: '4px',
            } : {})
          }}>
            <Routes>
              {!keycloak.authenticated && <Route exact path="/" element={<LandingPage />} />
              }

              {!!keycloak.authenticated && (
                <>
                  <Route exact path="/" element={classViewEnabled ? <ClassDashboardView /> : <OverView />} />
                  <Route exact path="/strength-analysis" element={<StrengthAnalysis />} />
                  <Route exact path="/strength-analysis/:pathStrand/:pathUnit" element={<StrengthAnalysis />} />
                  <Route exact path="/feedback" element={<Feedback />} />
                </>)
              }
              <Route path="/legacy" element={<Welcome />} />
              <Route path="/draw" element={<ExcalidrawComponent />} />
              {/* For DEV purpose only */}
               <Route path="/dev-overview" element={<ClassDashboardView />} />
              <Route
                path="/admin-only-route"
                element={
                  <RolesRoute roles={['admin']}>
                    <AdminOnlyRoute />
                  </RolesRoute>
                }
              />
            </Routes>

          </Content>

        </Layout>
        {!!keycloak.authenticated && !classViewEnabled && isMobile && <NavBar />}
      </BrowserRouter>
    </Layout>
  </ConfigProvider>);
};

export default App;
