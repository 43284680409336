import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Modal, Table } from 'antd';

const TotalStudentDistribution = ({ studentDistributionData }) => {
  const { aboveLevel, atLevel, belowLevel } = studentDistributionData;
  const [chartData, setChartData] = useState([aboveLevel.length, atLevel.length, belowLevel.length]);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setChartData([aboveLevel.length, atLevel.length, belowLevel.length]);
  }, [aboveLevel, atLevel, belowLevel]);

  useEffect(() => {
    console.log('Table data: ', tableData)
  }, [tableData]);

  useEffect(() => {
    // Update tableData based on the selected level
    if (showTable) {
      let selectedStudents = [];
      switch (selectedLevel) {
        case "Above level":
          selectedStudents = studentDistributionData.aboveLevel;
          break;
        case "At level":
          selectedStudents = studentDistributionData.atLevel;
          break;
        case "Below level":
          selectedStudents = studentDistributionData.belowLevel;
          break;
        default:
          selectedStudents = [];
      }
      console.log('Selected students : ', selectedStudents);
      setTableData(selectedStudents);
    }
  }, [studentDistributionData, showTable]);

  const [selectedLevel, setSelectedLevel] = useState(null);

  const chartOptions = {
    labels: ["Above level", "At level", "Below level"],
    colors: ["#4CAF50", "#FFC107", "#F44336"],
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
      },
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const seriesIndex = config.dataPointIndex;
          const level = chartOptions.labels[seriesIndex];
          setSelectedLevel(level);
          setShowTable(true);
        },
      },
    },
  };

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const dataSource = tableData.map((student, index) => ({
    key: index,
    name: student,
    index: index + 1
  }));

  const handleOk = () => {
    setShowTable(false);
  };

  const handleCancel = () => {
    setShowTable(false);
  };

  return (
    <div className="chart-container">
      <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Student Level Overview</p>
      <Chart series={chartData} options={chartOptions} type="donut" width={400} />
      <Modal
        title={selectedLevel}
        open={showTable}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table dataSource={dataSource} columns={columns} pagination={false}/>
      </Modal>
    </div>
  );
};

export default TotalStudentDistribution;